const SESSIONS_ENDPOINT: string = process.env.REACT_APP_SESSIONS_ENDPOINT!;
const ZOOM_ENDPOINT: string = process.env.REACT_APP_ZOOM_ENDPOINT!;

const worker = new Worker(
  new URL("../worker/userLeaveWorker.js", import.meta.url)
);

worker.addEventListener("message", (event) => {
  console.log("Message from worker:", event.data);
});

export const sendJoinSessionEvent = async (
  sessionId: string,
  token: string
) => {
  const response = await fetch(`${SESSIONS_ENDPOINT}/session/event`, {
    method: "POST",
    headers: {
      Authorization: `${token}`,
    },
    body: JSON.stringify({ id: sessionId, type: "join" }),
  });

  return response;
};

export const sendLeaveSessionEvent = (sessionId: string, token: string) => {
  const url = `${SESSIONS_ENDPOINT}/session/event`;

  worker.postMessage({ url, sessionId, token });
};

export const fetchZoomZakTokenForTutor = async (
  zoomUserId: string,
  token: string
): Promise<string> => {
  const url = `${ZOOM_ENDPOINT}/conference/zak`;

  const response = await fetch(url, {
    method: "POST",
    headers: {
      Authorization: token,
    },
    body: JSON.stringify({
      zoomUserId,
    }),
  });

  return (await response.json()).token;
};
