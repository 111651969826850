import { createSlice } from "@reduxjs/toolkit";
import {
  sendJoinSessionEvent,
  sendLeaveSessionEvent,
} from "../repositories/sessionRepository";

export interface ConferenceSliceProps {
  sessionId?: string;
  joined?: Date;
  leaved?: Date;
}

export const conferenceSlice = createSlice({
  name: "conference",
  initialState: {} as ConferenceSliceProps,
  reducers: {
    updateConferenceData: (state, action) => {
      console.log("🚀 ~ file: conferenceSlices.ts:14 ~ state:", state);
      state.sessionId = action.payload.sessionId;
    },
    onUserJoin: (state, action) => {
      console.log(`User joined with state`, state);
      console.log(`User joined with action`, action);
      state.joined = new Date();
      sendJoinSessionEvent(
        action.payload.sessionId!,
        action.payload.token
      ).then((res) => console.info(`User join confirmed`, res));
    },
    onUserLeave: (state, action) => {
      console.log(`User leaved with state ${JSON.stringify(state)}`);
      state.leaved = new Date();
      sendLeaveSessionEvent(state.sessionId!, action.payload.token);
    },
  },
});

export const { onUserJoin, onUserLeave, updateConferenceData } =
  conferenceSlice.actions;

export default conferenceSlice.reducer;

// Selecters
export const selectSessionId = (state: any) => state.conference.sessionId;
