import './App.css';
import { ZoomMtg } from '@zoom/meetingsdk';
import { ZoomRoles } from './zoomRoles';
import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { onUserJoin, selectSessionId, updateConferenceData } from './app/features/conferenceSlices';
import { updateUser, userTokenSelector } from './app/features/userSlice';
import { fetchZoomZakTokenForTutor, sendLeaveSessionEvent } from './app/repositories/sessionRepository';

// loads WebAssembly assets
ZoomMtg.preLoadWasm();
ZoomMtg.prepareWebSDK();
// loads language files, also passes any error messages to the ui
ZoomMtg.i18n.load('en-US')

const MEETING_COOKIE_NAME = 'meeting_data';

export interface MeetingData {
  sessionId: string;
  meetingNumber: string;
  passWord: string;
  role: ZoomRoles;
  userName: string;
  userEmail: string;
  signature: string;
  zak?: string;
  token: string;
  zoomUserId: string;
}

const ZOOM_SDK_CLIENT_ID = process.env.REACT_APP_ZOOM_SDK_CLIENT_ID;

function App() {

  const dispatch = useDispatch();
  const sessionId = useSelector(selectSessionId)
  const token = useSelector(userTokenSelector)

  const getUserInformation = useCallback(async () => {

    const cookies = document.cookie.split(';');
    const meetingDataCookie = cookies.map(c => c.trim()).find(cookie => cookie.startsWith(MEETING_COOKIE_NAME));
    const meetingDataEncoded = meetingDataCookie?.split("=")[1];
    const meetingDataDecoded = meetingDataEncoded ? decodeURIComponent(meetingDataEncoded) : undefined;
    const cookieData: MeetingData = meetingDataDecoded ? JSON.parse(meetingDataDecoded) : undefined;
    let zakToken = cookieData.zak;

    if (cookieData.role === ZoomRoles.HOST) {
      try {
        const zak = await fetchZoomZakTokenForTutor(cookieData.zoomUserId ?? '-OqITwOBQMuV7MDXZoEpcg', cookieData.token)
        zakToken = zak;
      } catch (e: any) {
        console.error(e);
      }
    }

    cookieData.zak = zakToken;
    dispatch(updateUser(cookieData));
    dispatch(updateConferenceData(cookieData));

    if (!cookieData) {
      alert("You can not join the session, please provide the correct data")
    }

    return cookieData

  }, [dispatch]);

  const startMeeting = useCallback(async () => {

    var meetingData = await getUserInformation();

    if (!meetingData) return;

    ZoomMtg.init({
      debug: false,
      patchJsMedia: true,
      leaveUrl: process.env.REACT_APP_ZOOM_LEAVE_URL!,
      disableInvite: true,
      disableRecord: true,
      screenShare: meetingData.role === ZoomRoles.HOST ? true : false,
      meetingInfo: ['topic', 'host'],
      disablePreview: true,

      success: () => {
        console.log("Initialized successfully")
        console.log({ meetingData })
        ZoomMtg.join({
          signature: meetingData.signature,
          sdkKey: ZOOM_SDK_CLIENT_ID,
          meetingNumber: meetingData.meetingNumber,
          passWord: meetingData.passWord,
          userName: meetingData.userName,
          userEmail: meetingData.userEmail,
          zak: meetingData.zak,
          success: () => {
            console.info("Session has started successfully")
            dispatch(onUserJoin({ token: meetingData.token, sessionId: meetingData.sessionId }));
          },
          error: (e: any) => {
            console.error(e);
          }
        })
      },
      error: (e: any) => {
        console.error(e)
      }
    });

  }, [getUserInformation, dispatch]);

  const handleUserLeave = useCallback((e: any) => {
    e.preventDefault();
    if (sessionId && token)
      sendLeaveSessionEvent(sessionId, token);

  }, [token, sessionId]);

  useEffect(() => {
    startMeeting()
    console.log(`Is Shared Buffer Array Working: ${typeof SharedArrayBuffer === 'function'}`);
  }, [startMeeting])

  useEffect(() => {
    window.addEventListener('beforeunload', handleUserLeave);
    /* window.addEventListener('unload', handleUserLeave);
    window.addEventListener('popstate', handleUserLeave);
 */
    return () => {
      // window.removeEventListener('beforeunload', handleUserLeave);
      //window.removeEventListener('unload', handleUserLeave);
      //window.removeEventListener('popstate', handleUserLeave);
    }
  }, [handleUserLeave]);

  console.log({ token, sessionId })

  return (
    <div className="App">
      <main>

      </main>
    </div>
  );
}

export default App;
