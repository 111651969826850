import { createSlice } from "@reduxjs/toolkit";

export const userSlice = createSlice({
  name: "user",
  initialState: {
    userName: "",
    email: "",
    token: "",
  },
  reducers: {
    updateUser: (state, action) => {
      state.token = action.payload.token;
      state.userName = action.payload.userName;
      state.email = action.payload.userEmail;
    },
  },
});

export const { updateUser } = userSlice.actions;

export const userTokenSelector = (state: any) => state.user.token;

export default userSlice.reducer;
